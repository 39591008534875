const getColor = (value: number, maxValue: number) => {
  const ratio = 1 - value / maxValue;
  const r = Math.round(26 + (122 - 26) * ratio);
  const g = Math.round(115 + (219 - 115) * ratio);
  const b = Math.round(232 + (255 - 232) * ratio);
  return `rgb(${r}, ${g}, ${b})`;
};

const aggregatedDataTransformation = (
  data: any,
  dimensionIndex: number,
  metricIndex: number
) =>
  data.rows.reduce((acc: any, row: any) => {
    const dimension = row.dimensionValues[dimensionIndex]?.value;
    const metric = parseInt(row.metricValues[metricIndex]?.value || "0", 10);

    if (dimension) {
      if (acc[dimension]) {
        acc[dimension] += metric;
      } else {
        acc[dimension] = metric;
      }
    }
    return acc;
  }, {});

const parseDate = (dateString: string) => {
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1; // Los meses en JavaScript son 0-11
  const day = parseInt(dateString.substring(6, 8));
  return new Date(year, month, day);
};

export const lineAggregatedData = (
  data: any,
  chartTitle: string
) => {
  const dateFormatter = new Intl.DateTimeFormat("es-ES", {
    day: "2-digit",
    month: "short",
  });

  const lineAggregatedData = aggregatedDataTransformation(data, 0, 0);
  const transformedAggregationData = Object.entries(lineAggregatedData)
  .map(([dimension, metric]) => ({
    date: dateFormatter.format(
      parseDate(dimension)
    ),
    activeUsers: parseInt(metric as string),
  }));

  const chartData = {
    labels: transformedAggregationData.map((item) => item.date),
    datasets: [
      {
        label: "",
        data: transformedAggregationData.map((item) => item.activeUsers),
        borderColor: "#f6f008",
        pointBorderColor: '#f6f008',
        pointBackgroundColor: '#f6f008',
        fill: true,
        tension: 0.1
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: chartTitle,
        color: "#ffffff", // Color del título
        font: {
          size: 24,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Ajusta la suavidad de las líneas
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
        },
      },
      y: {
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return { chartData, chartOptions: options };
};


export const aggregatedData = (
  data: any,
  chartTitle: string,
  datasetOpt: any,
  chartOpt: any,
) => {
  const aggregatedD = aggregatedDataTransformation(
    data,
    0,
    0
  );
  const transformedAggregationData = Object.entries(aggregatedD)
    ?.slice(0, 7)
    .map(([dimension, metric]) => ({
      dimension,
      metric,
    }))
    .sort((a: any, b: any) => b.metric - a.metric);

  const maxValueInMetric = Math.max(
    ...(transformedAggregationData?.map((item: any) => item.metric) || [0])
  );

  const chartData = {
    labels:
      transformedAggregationData?.map(
        (item: any) => `${item.dimension} (${item.metric})`
      ) || [],
    datasets: [
      {
        label: "",
        data: transformedAggregationData?.map((item: any) => item.metric) || [],
        backgroundColor:
          transformedAggregationData?.map((item: any) =>
            getColor(item.metric, maxValueInMetric)
          ) || [],
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 0,
        ...datasetOpt,
      },
    ],
  };

  const chartOptions = {
    indexAxis: "y" as const,
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: chartTitle,
        color: "#ffffff",
        font: {
          size: 24,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
        },
      },
      y: {
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
        },
      },
    },
    ...chartOpt,
  };

  return { chartData, chartOptions };
};

const paddingTopDependingOnNumberOfItems: Record<number, number> = {
  1: 45,
  2: 40,
  3: 35,
  4: 30,
  5: 25,
  6: 20,
  7: 15,
};

export const getStyledBarChart = <T>(
  data: T,
  chartTitle: string
) => {
  const index = Object.keys(
    aggregatedDataTransformation(data, 0, 0)
  ).length;

  return {
    type: "bar",
    data,
    datasetOpt: {
      borderRadius: 16,
      borderSkipped: false,
      barThickness: 6,
    },
    chartOpt: {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: "start",
          align: "315",
          color: "#ffffff",
          offset: -8,
          padding: { top: paddingTopDependingOnNumberOfItems[index as number] },
          font: {
            size: 14,
          },
          formatter: (value: string, context: any) => {
            // Combina el label (etiqueta) y el valor
            const label = context.chart.data.labels[context.dataIndex];
            return label;
          },
        },
        title: {
          display: true,
          text: chartTitle,
          color: "#ffffff",
          font: {
            size: 24,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ffffff",
            font: {
              size: 14,
            },
          },
        },
        y: {
          ticks: {
            display: false,
            color: "#ffffff",
            font: {
              size: 14,
            },
          },
        },
      },
    },
  };
};


// Función para procesar los datos y obtener los usuarios activos de ayer
export const getActiveUsersFromYesterday = (data: any) => {
  const dateFormatter = new Intl.DateTimeFormat("es-ES", {
    day: "2-digit",
    month: "short",
  });

  const lineAggregatedData = aggregatedDataTransformation(data, 0, 0);
  const transformedAggregationData = Object.entries(lineAggregatedData)
  .map(([dimension, metric]) => ({
    date: dateFormatter.format(
      parseDate(dimension)
    ),
    activeUsers: parseInt(metric as string),
  }));

  return transformedAggregationData[transformedAggregationData.length - 1].activeUsers;
};




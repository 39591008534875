import React from 'react';
import { NavElement } from '../../types/navigation';
import { Tab } from './Tab/Tab';
import './Tabs.scss';

interface Props {
  tabs: NavElement[];
}

export const Tabs = ({ tabs }: Props) => {
  return (
    <nav className='tabs'>
      {tabs.map(({ url, label = '' }) => (
        <Tab key={url} href={url} label={label} />
      ))}
    </nav>
  );
};

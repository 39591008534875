import React, { FormEvent, useEffect, useState } from 'react';

import { ACCESS_TOKEN_STORAGE_KEY } from '../../config/auth';
import arrow from '../../assets/svgs/arrow.svg';

import './Login.scss';
import { Button } from '../../components/Button/Button';
import { useAuthContext } from '../../context/AuthContext';
import { Input } from '../../components/Input/Input';

export const Login = () => {
  const { login } = useAuthContext() ?? {};
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();
    setIsSigningIn(true);
    try {
      await login?.(email, password);      
    } catch (error) {
      alert(`Sign in failed: ${error}`);
    }
    setIsSigningIn(false);
  };

  return (
    <div className='login'>
      <h1>No Gravity</h1>
      <h4>Sign in to your admin account</h4>
      <form onSubmit={handleSignIn}>
        <Input
          id='email'
          label='email'
          name='email'
          value={email}
          type='email'
          inputMode='email'
          autoComplete='email'
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          label='Password'
          id='password'
          name='password'
          value={password}
          type='password'
          inputMode='text'
          autoComplete='password'
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          type='submit'
          className='span-big-space'
          iconAlt='Sign in'
          icon={arrow}
          loading={isSigningIn}
          large
        >
          Sign in
        </Button>
      </form>
    </div>
  );
};

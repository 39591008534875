import React, { Fragment, useEffect, useState } from "react";
import { PageTemplate } from "../../layout/PageTemplate/PageTemplate";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import "./Profile.scss";

export const Profile = () => {
  const navigate = useNavigate();
  const { signOut, user, updateUser } = useAuthContext() ?? {};
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleSignOut = async () => {
    setIsSigningOut(true);
    await signOut?.();
    navigate("/");
    setIsSigningOut(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUserData({
        ...userData,
        avatar: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Profile updated:", userData);
    updateUser?.(userData);
  };

  return (
    <PageTemplate title="Profile" className="profile-page">
      {userData && (
        <Fragment>
          <div className="avatar-section">
            <figure className="avatar-card-image">
              <img
                src="https://c1.staticflickr.com/4/3935/32253842574_d3d449ab86_c.jpg"
                alt="Short description"
              ></img>
            </figure>
            <label htmlFor="avatar-input" className="avatar-input">
              {userData.profileImage ? (
                <Fragment>
                <img
                  src={userData.profileImage}
                  alt="Avatar"
                  className="avatar-img"
                />
                <p className="avatar-user-name">{userData.name}</p>
                </Fragment>
              ) : (
                <div className="avatar-placeholder">Upload Avatar</div>
              )}
            </label>
            <input
              type="file"
              id="avatar-input"
              name="avatar"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button disabled={isSigningOut} onClick={handleSignOut}>
              Sign out
            </Button>
          </div>
          <div className="profile-container">
            <p className="profile-form-title">User information</p>
            <form className="profile-form" onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="input-group">
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </div>
              <Button type="submit">
                Save Changes
              </Button>
            </form>
          </div>
        </Fragment>
      )}
    </PageTemplate>
  );
};

import React, { FormEvent, useEffect, useState } from 'react';
import './PostEditor.scss';
import { Input } from '../../../../components/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { TextArea } from '../../../../components/TextArea/TextArea';
import { SubPageTemplate } from '../../../../layout/SubPageTemplate/SubPageTemplate';
import { FileUpload } from '../../../../components/FileUpload/FileUpload';
import { RichTextEditor } from '../../../../components/RichTextEditor/RichTextEditor';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useAuthContext } from '../../../../context/AuthContext';

export interface PostsResponse {
  id: string;
  creationDate: string;
  es: Post;
  en: Post;
}

export interface Post {
  meta: {
    url: string;
    title: string;
    description: string;
  };
  title: string;
  description: string;
  image: {
    url: string;
    dashboardUrl: string;
    alt: string;
  };
  content: string;
}

const locale = 'en';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const PostEditor = () => {
  const { user } = useAuthContext() ?? {};
  const { id } = useParams();
  const [imageFile, setImageFile] = useState<File>();
  const [post, setPost] = useState<Post>();
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading, error } = useSWR(
    `http://localhost:3000/${user?.id}/posts/${id}?locale=${locale}`,
    fetcher
  );

  useEffect(() => {
    if (data && !post) {
      setPost(data[locale]);
    }
  }, [data]);

  const savePost = async () => {
    if (!post) return;
    setIsSaving(true);
    const formData = new FormData();

    if (imageFile) {
      formData.append('image', imageFile);
    }
    formData.append('data', JSON.stringify({ [locale]: post }));

    try {
      const response = await fetch(`http://localhost:3000/${user?.id}/posts/${id}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      // Parse the response as JSON
      const saveResponse = await response.json();
      console.log('Success:', saveResponse);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsSaving(false);
  };

  const updatePost = (newData: Partial<Post>) => {
    if (!post) return;
    setPost({ ...post, ...newData });
  };
  const submitPost = async (e: FormEvent) => {
    e.preventDefault();
    console.log({ post, e });
    savePost();
  };

  return (
    <SubPageTemplate title='Edit post'>
      {isLoading ? (
         <div className="analytics-loader">
         <img src="https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/webs/no-gravity-web/logo-loader.svg"></img>
         <p className="analytics-loading-text">Loading...</p>
       </div>
      ) : (
        <form className='post-editor-form' onSubmit={submitPost}>
          {post && (
            <>
              <Input
                id='title'
                label='title'
                name='title'
                value={post?.title}
                onChange={(e) => updatePost({ title: e.target.value })}
                required
                small
              />
              <TextArea
                id='description'
                label='description'
                name='description'
                value={post?.description}
                onChange={(e) => updatePost({ description: e.target.value })}
                required
                small
              />
              <FileUpload
                value={post?.image.dashboardUrl}
                onImageUpload={(newImage, file) => {
                  setImageFile(file);
                  updatePost({ image: { ...post?.image, dashboardUrl: newImage } });
                }}
                label='image'
                id='post-image-input'
                small
              />
              <Input
                label='image alt'
                id='image-alt'
                name='image-alt'
                value={post?.image.alt}
                onChange={(e) => updatePost({ image: { ...post?.image, alt: e.target.value } })}
                required
                small
              />
              <RichTextEditor
                value={post?.content}
                id='post-content-editor'
                label='content'
                onBlur={(value) => updatePost({ content: value })}
              />
              <Input
                id='url'
                label='url'
                name='url'
                value={post?.meta.url}
                onChange={(e) => updatePost({ meta: { ...post?.meta, url: e.target.value } })}
                required
                small
              />
              <Input
                id='meta-title'
                label='meta title'
                name='meta-title'
                value={post?.meta.title}
                onChange={(e) => updatePost({ meta: { ...post?.meta, title: e.target.value } })}
                required
                small
              />
              <Input
                id='meta-description'
                label='meta description'
                name='meta-description'
                value={post?.meta.description}
                onChange={(e) =>
                  updatePost({ meta: { ...post?.meta, description: e.target.value } })
                }
                required
                small
              />
              <Button
                loading={isSaving}
                type='submit'
                className='large span-big-space'
                iconAlt='Edit post'
              >
                Save changes
              </Button>
            </>
          )}
        </form>
      )}
    </SubPageTemplate>
  );
};

import { useEffect, useState } from 'react';

export const useIsDraggingFile = () => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    let dragCounter = 0;
    window.addEventListener('dragenter', () => {
      if (dragCounter === 0) {
        setIsDragging(true);
      }
      dragCounter++;
    });

    window.addEventListener('dragleave', () => {
      dragCounter--;
      if (dragCounter === 0) {
        setIsDragging(false);
      }
    });

    window.addEventListener('drop', () => {
      dragCounter = 0;
      setIsDragging(false);
    });

    window.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
  }, []);

  return isDragging;
};

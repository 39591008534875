import React from 'react';
import { ContentManager } from '../pages/ContentManager/ContentManager';
import Analytics  from '../pages/Analytics/Analytics';
import { Automation } from '../pages/Automation/Automation';
import { Profile } from '../pages/Profile/Profile';
import { NavElement } from '../types/navigation';

export const pages: NavElement[] = [
  {
    label: 'Analytics',
    url: '/analytics',
    element: <Analytics />,
    icon: 'https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/new-analytics.svg'
  },
  {
    label: 'Content',
    url: '/content',
    element: <ContentManager />,
    hasNestedRoutes: true,
    icon: 'https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/website.svg'
  },
  {
    label: 'Automation',
    url: '/automation',
    element: <Automation />,
    icon: 'https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/rpa.svg'
  },
  {
    label: 'Profile',
    url: '/profile',
    element: <Profile />,
    icon: 'https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/avatar.svg'
  },
];

import React, { ReactNode, useState } from 'react';

import './FormElement.scss';

export interface Props {
  elementId: string;
  label: string;
  children: ReactNode;
  hasValue: boolean;
  required: boolean;
  small: boolean;
  onFocus?: () => void;
}

export const FormElement = ({
  children,
  elementId,
  label,
  hasValue,
  required,
  small,
  onFocus,
}: Props) => {
  const [focused, setFocused] = useState(false);

  const isFloatingLabel = focused || hasValue;

  const handleFocus = () => {
    setFocused(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div className={`form-element${small ? ' small' : ''}`}>
      <label
        htmlFor={elementId}
        id='email-label'
        className={`form-element-label${isFloatingLabel ? ' input-focus' : ''}`}
      >
        {label}
        {required ? '*' : ''}
      </label>
      <div className='form-element-inner' onFocus={handleFocus} onBlur={handleBlur}>
        <>{children}</>
      </div>
    </div>
  );
};

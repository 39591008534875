import React, { useEffect, useState } from "react";
import { PageTemplate } from "../../layout/PageTemplate/PageTemplate";
import "./Automation.scss";
import { AutomationCard } from "../../components/AutomationCard/AutomationCard";
import { AutomationData } from "../../types/automation";
import { useAuthContext } from "../../context/AuthContext";

export const Automation = () => {
  const { user } = useAuthContext() ?? {};
  const [automationData, setAutomationData] = useState<AutomationData>();

  useEffect(() => {
    user && fetchData(user.id);
  }, [user]);

  const fetchData = (userId: string) => {
    fetch(
      `http://127.0.0.1:3000/automation/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((automation) => {
        setAutomationData(automation);
      })
      .catch((error) => {
        console.error("Error al obtener datos de Automation:", error);
      });
  };

  return (
    <PageTemplate title="Automation">
      <div className="wrapper">
        <AutomationCard disabled={!automationData?.invoices} title={'Facturas'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/paid.png" text="Automatizar la generación y el envío de facturas, recordatorios de pago, y conciliaciones bancarias"></AutomationCard>
        <AutomationCard disabled={!automationData?.automatedDocumentGeneration} title={'Generación automática de documentos'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/documents.png" text="Automatizar la creación de contratos, propuestas o informes personalizados basados en plantillas y datos del cliente"></AutomationCard>
        <AutomationCard disabled={!automationData?.workflowAutomation} title={'Flujos de trabajo'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/workflow.png" text="Ayuden a los clientes a automatizar procesos internos como la aprobación de documentos, la gestión de permisos o el seguimiento de tareas"></AutomationCard>
        <AutomationCard disabled={!automationData?.ticketSystem} title={'Sistema de tickets'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/tickets.png" text="Automatizar la creación, asignación y seguimiento de tickets de soporte para mejorar la eficiencia del servicio al cliente"></AutomationCard>
        <AutomationCard disabled={!automationData?.salesReporting} title={'Informe de ventas'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/sales.png" text="Automatizar la creación de informes de rendimiento de ventas, analizando tendencias y oportunidades"></AutomationCard>
        <AutomationCard disabled={!automationData?.inventoryManagement} title={'Gestion de inventario'} imageUrl="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/warehouse.png" text="Automatizar la actualización del inventario en tiempo real, sincronizando entre la tienda en línea y sistemas internos"></AutomationCard>
      </div>
    </PageTemplate>
  );
};

import React from 'react';
import './ImagePreview.scss';

interface Props {
  previewUrl: string;
}

export const ImagePreview = ({ previewUrl }: Props) => {
  return <img src={previewUrl} alt='Preview' className='image-preview' />;
};

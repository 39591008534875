import React, { PropsWithChildren, createContext, useContext } from 'react';

type DragProviderProps = PropsWithChildren<{ value: boolean }>;

const initialValue: boolean = false;

const DragContext = createContext<boolean>(initialValue);

export const DragProvider = ({ value, children }: DragProviderProps) => {
  return <DragContext.Provider value={value}>{children}</DragContext.Provider>;
};

export const useDragContext = () => {
  return useContext(DragContext);
};

import React from "react";
import "./AutomationCard.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";

interface Props {
  title: string;
  text: string;
  url?: string;
  imageUrl: string;
  disabled?: boolean;
}

export const AutomationCard = ({ title, text, url, imageUrl, disabled  }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={`automation-card ${disabled ? 'card-disabled' : ''}`} onClick={() => url && !disabled && navigate(url)}>
      <img src={imageUrl} />
      <div className="info">
        <h1>{title}</h1>
        <p>{text}</p>
        { !disabled ? <Button>Entrar</Button> : <Button>Contáctanos</Button> }
      </div>
    </div>
  );
};

import React, {
  ChangeEventHandler,
  TextareaHTMLAttributes,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormElement } from '../FormElement/FormElement';
import './TextArea.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label: string;
  small?: boolean;
}

const TEXT_AREA_BOTTOM_OFFSET = 5;

export const TextArea = ({
  label,
  id,
  small = false,
  value,
  className = '',
  onChange,
  required = false,
  ...restProps
}: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const resizeTextarea = () => {
    if (!ref.current) return;
    ref.current.style.height = 'auto';
    ref.current.style.height = ref.current.scrollHeight + 'px';
  };

  useLayoutEffect(() => {
    resizeTextarea();
  }, []);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange?.(e);
    resizeTextarea();
  };

  return (
    <FormElement elementId={id} label={label} hasValue={!!value} required={required} small={small}>
      <textarea
        ref={ref}
        className={`${className}${small ? ' small' : ''}`}
        onChange={handleChange}
        id={id}
        value={value}
        required={required}
        rows={1}
        {...restProps}
      />
      <div className='border-animation' />
    </FormElement>
  );
};

import React, { ButtonHTMLAttributes } from 'react';

import './Button.scss';
import { SpanText, SpanTextTypes } from '../SpanText/SpanText';
import { Spinner } from '../Spinner/Spinner';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  icon?: '*.svg';
  iconAlt?: string;
  loading?: boolean;
  large?: boolean;
}

export const Button = ({
  icon,
  iconAlt,
  children,
  className,
  loading = false,
  large = false,
  ...restProps
}: Props) => {
  const showIcon = !!icon && !loading;
  return (
    <button
      className={`button scramble-hover${loading ? ' loading' : ''}${large ? ' large' : ''} ${
        className ?? ''
      }`}
      {...restProps}
    >
      <div className='scramble'>
        <SpanText type={SpanTextTypes.Letters} trim>
          {children}
        </SpanText>
      </div>
      {showIcon && <img loading='lazy' src={icon} alt={iconAlt} />}
      {loading && <Spinner />}
    </button>
  );
};

import React from 'react';
import { pages } from '../../config/pages';
import { SidebarButton } from './SidebarButton/SidebarButton';

import './Sidebar.scss';

export const Sidebar = () => {
  return (
    <nav className='sidebar'>
      <div className='sidebar-image-container'>
        <img src="https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/webs/no-gravity-web/fake-adri.webp"></img>
      </div>
      {pages.map(({ url, label = '', icon }) => (
        <SidebarButton key={url} href={url} label={label} icon={icon}></SidebarButton>
      ))}
    </nav>
  );
};

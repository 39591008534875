import React from 'react';
import { signOut } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import './Header.scss';

export const Header = () => {
  return (
    <header>
      <Link className='header-logo' to='/'>
        <img
          src='https://no-gravity.s3.eu-north-1.amazonaws.com/common/no-gravity-logo-white_csdgjh.png'
          alt='No Gravity logo'
        />
      </Link>
      <h1>DASHBOARD</h1>
      <div className='locale-picker'>EN / ES</div>
    </header>
  );
};

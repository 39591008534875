import React, { useEffect, useState } from "react";
import { PageTemplate } from "../../layout/PageTemplate/PageTemplate";

import {
  getActiveUsersFromYesterday,
  getStyledBarChart,
} from "../../utils/chart-utils";
import { BarChart } from "../../components/BarChart/BarChart";
import { LineChart } from "../../components/LineChart/LineChart";
import "./Analytics.scss";
import { useAuthContext } from "../../context/AuthContext";

const Analytics = () => {
  const { user } = useAuthContext() ?? {};
  const [dataForChart, setDataForChart] = useState<any[]>([]);

  useEffect(() => {
    user && fetchData();
  }, [user]);

  const fetchData = () => {
    fetch(`http://127.0.0.1:3000/analytics/${user?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const chartsData = [
          {
            type: "line",
            data: data.usersOverTime,
            chartTitle: "Nuevos Usuarios en el tiempo",
          },
          {
            type: "card",
            data: getActiveUsersFromYesterday(data.usersOverTime),
            chartTitle: "Usuarios activos ayer",
          },
          {
            type: "bar",
            data: data.countryDistribution,
            chartTitle: "Distribución por País",
          },
          getStyledBarChart(
            data.usersByCategory,
            "Nuevos Usuarios por Categoría"
          ),
          {
            type: "bar",
            data: data.sessionsByCategory,
            chartTitle: "Sesiones por Categoría",
          },
          getStyledBarChart(
            data.viewsByPage,
            "Views by Page Title and Screen Class"
          ),
        ];
        setDataForChart(chartsData);
      })
      .catch((error) => {
        console.error("Error al obtener datos de Analytics:", error);
      });
  };

  const getElementToDisplay = (dataSet: any, key: number) => {
    switch (dataSet.type) {
      case "line":
        return <LineChart {...dataSet} key={`${key}`}></LineChart>;
      case "bar":
        return <BarChart {...dataSet} key={`${key}`}></BarChart>;
      case "card":
        return (
          <div className="yesterday-user-card" key={`${key}`}>
            <figure className="avatar-card-image">
              <img
                src="https://no-gravity.s3.eu-north-1.amazonaws.com/Dashboard/earth-is-world-s-largest-planet.jpg"
                alt="Short description"
              ></img>
            </figure>
            <h5>{dataSet.chartTitle}</h5>
            <p className="x-large">{dataSet.data}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <PageTemplate title="Analytics" className="analytics-page">
      {dataForChart.length > 0 ? (
        <div className="analytics-container">
          {dataForChart?.map((dataSet: any, index: number) => {
            return getElementToDisplay(dataSet, index);
          })}
        </div>
      ) : (
        <div className="analytics-loader">
          <img src="https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/webs/no-gravity-web/logo-loader.svg"></img>
          <p className="analytics-loading-text">Loading...</p>
        </div>
      )}
    </PageTemplate>
  );
};

export default Analytics;

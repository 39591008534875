import React, { AnchorHTMLAttributes } from 'react';
import { SpanText, SpanTextTypes } from '../SpanText/SpanText';
import './Button.scss';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  children: string;
  icon?: '*.svg';
  iconAlt?: string;
}

export const LinkButton = ({ icon, iconAlt, children, className, ...restProps }: Props) => {
  return (
    <Link className={`button scramble-hover ${className ?? ''}`} {...restProps}>
      <div className='scramble'>
        <SpanText type={SpanTextTypes.Letters} trim>
          {children}
        </SpanText>
      </div>
      {icon && <img loading='lazy' src={icon} alt={iconAlt} />}
    </Link>
  );
};

import React, { PropsWithChildren } from 'react';

import './PageTemplate.scss';
import { NavElement } from '../../types/navigation';
import { Tabs } from '../Tabs/Tabs';
import { RoutesGroup } from '../RoutesGroup/RoutesGroup';
import { useRouteSteps } from '../../hooks/useRouteSteps';

interface Props {
  title: string;
  tabs?: NavElement[];
  subPages?: NavElement[];
  className?: string
}

export const PageTemplate = ({
  children,
  title,
  tabs,
  subPages = [],
  className
}: PropsWithChildren<Props>) => {
  const { isBasePage } = useRouteSteps(!!tabs);

  return (
    <>
      <h3 className='page-title'>{title}</h3>
      <div className={`page-card ${className ? className : ''}`}>
        {tabs ? (
          <>
            {isBasePage && <Tabs tabs={tabs} />}
            <div className='page-content'>
              <RoutesGroup navElements={[...tabs, ...subPages]} />
            </div>
          </>
        ) : (
          <div className='page-content'>{children}</div>
        )}
      </div>
    </>
  );
};

import React from "react";
import { lineAggregatedData } from "../../utils/chart-utils";
import "./LineChart.scss";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  PointElement,
  LineElement
);

interface Props {
  data: any;
  chartTitle: string;
}

export const LineChart = ({ data, chartTitle }: Props) => {
  const dataToShow = lineAggregatedData(data, chartTitle);

  return (
    <div>
      <Line
        className="line-chart"
        data={dataToShow.chartData}
        options={dataToShow.chartOptions as any}
      />
    </div>
  );
};

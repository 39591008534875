import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { BlogContent } from './BlogContent/BlogContent';
import { PostEditor } from './BlogContent/PostEditor/PostEditor';
import { NavElement } from '../../types/navigation';

const tabs: NavElement[] = [
  {
    label: 'Blog',
    element: <BlogContent />,
    url: 'blog',
  },
  {
    label: 'Portfolio',
    element: <></>,
    url: 'portfolio',
  },
];

const subPages: NavElement[] = [
  {
    label: 'Edit post',
    element: <PostEditor />,
    url: 'blog/post/:id',
  },
];

export const ContentManager = () => {
  return (
    <PageTemplate title='Content Manager' tabs={tabs} subPages={subPages}>
      {/* <BlogContent />
      <PostEditor /> */}
    </PageTemplate>
  );
};

import React from "react";
import { aggregatedData } from "../../utils/chart-utils";
import "./BarChart.scss";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  PointElement,
  LineElement
);

interface Props {
  data: any;
  chartTitle: string;
  datasetOpt: any;
  chartOpt: any;
  maxLength?: number;
}

export const BarChart = ({ data, chartTitle, datasetOpt, chartOpt }: Props) => {
  const dataToShow = aggregatedData(data, chartTitle, datasetOpt, chartOpt);

  return (
    <div>
      <Bar
        className="bar-chart"
        data={dataToShow.chartData}
        options={dataToShow.chartOptions}
      />
    </div>
  );
};

import React, { Fragment } from 'react';

import { posts } from '../../../mocks/posts.json';
import { LinkButton } from '../../../components/Button/LinkButton';
import './BlogContent.scss';
import useSWR from 'swr';
import { Post, PostsResponse } from './PostEditor/PostEditor';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const postLocale = 'en';
export const BlogContent = () => {
  const { data, isLoading, error } = useSWR(
    'http://localhost:3000/66991ceef7ee89f7363adf37/posts',
    fetcher
  );

  const renderPostPreview = (post: PostsResponse) => {
    const localePost = post[postLocale];
    if (!localePost) return <Fragment key={post.id}></Fragment>;

    return (
      <li key={post.id}>
        <img
          className='media'
          loading='lazy'
          src={post[postLocale].image.dashboardUrl}
          alt={post[postLocale].image.alt}
        />
        <div className='post-summary'>
          <h5>{post[postLocale].title}</h5>
          <p className='post-description'>{post[postLocale].description}</p>
          <div className='post-footer'>
            <p className='post-publish-date'>Published on&nbsp;{post.creationDate}</p>
            <LinkButton to={`post/${post.id}`}>EDIT</LinkButton>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      <h4>Posts</h4>
      {isLoading ? (
        <div className="analytics-loader">
        <img src="https://s3.eu-north-1.amazonaws.com/cdn.no-gravity.es/webs/no-gravity-web/logo-loader.svg"></img>
        <p className="analytics-loading-text">Loading...</p>
      </div>
      ) : (
        <ul className='post-list'>{data?.map((post: PostsResponse) => renderPostPreview(post))}</ul>
      )}
    </>
  );
};

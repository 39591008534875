import { Location, Navigate, Route, Routes } from 'react-router-dom';
import { NavElement } from '../../types/navigation';
import React from 'react';

export const addCatchAllRoute = (navElements: NavElement[]): NavElement[] => [
  ...navElements,
  {
    url: '/*',
    element: <Navigate to={navElements[0].url} replace />,
  },
];

const getPath = (url: string, hasNestedRoutes = false) => {
  return `${url}${hasNestedRoutes ? '/*' : ''}`;
};

interface Props {
  navElements: NavElement[];
}

export const RoutesGroup = ({ navElements }: Props) => {
  return (
    <Routes>
      {addCatchAllRoute(navElements).map(({ element, url, hasNestedRoutes }) => (
        <Route key={url} path={getPath(url, hasNestedRoutes)} element={element} />
      ))}
    </Routes>
  );
};

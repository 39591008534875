import React, { PropsWithChildren } from 'react';
import './SubPageTemplate.scss';
import { LinkButton } from '../../components/Button/LinkButton';

interface Props {
  title: string;
}

export const SubPageTemplate = ({ title, children }: PropsWithChildren<Props>) => {
  return (
    <>
      <div className='sub-page-header'>
        <h4>{title}</h4>
        <LinkButton className='small capitalize' to='../'>
          Back
        </LinkButton>
      </div>
      {children}
    </>
  );
};

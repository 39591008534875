import React, { PropsWithChildren, createContext, useContext } from 'react';
import { User } from '../types/user';

interface AuthContextValue {
  signOut: () => Promise<void>;
  user: User | undefined;
  fetchUser: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  updateUser: (user: User) => Promise<void>;
}
type AuthProviderProps = PropsWithChildren<{ value: AuthContextValue }>;

const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider = ({ value, children }: AuthProviderProps) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

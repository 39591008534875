import React from 'react';

export enum SpanTextTypes {
  Words = ' ',
  Letters = '',
}

interface Props {
  children: string;
  type: SpanTextTypes;
  className?: string;
  trim?: boolean;
}

export const SpanText = ({ children, className, type, trim = false }: Props) => {
  const renderWordType = (char: string) => (
    <span className='scroll-slice-in span-word'>{char.trim()}</span>
  );

  const renderLetterType = (char: string) => (trim ? char.trim() : char);

  return children.split(type).map((char, index) => {
    return (
      <span key={char + index} className={className}>
        {type === SpanTextTypes.Words && renderWordType(char)}
        {type === SpanTextTypes.Letters && renderLetterType(char)}
      </span>
    );
  });
};

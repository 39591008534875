import React, { useEffect, useState } from 'react';
import { Header } from './layout/Header/Header';
import { Sidebar } from './layout/Sidebar/Sidebar';
import { Pages } from './layout/Pages/Pages';
import { BrowserRouter } from 'react-router-dom';
import { Login } from './layout/Login/Login';
import { ACCESS_TOKEN_STORAGE_KEY, USER_ID } from './config/auth';
import { AuthProvider } from './context/AuthContext';
import { emitCustomEvent } from './utils/events';
import { DragProvider } from './context/DragContext';
import { useIsDraggingFile } from './hooks/useIsDraggingFile';
import { User } from './types/user';

const App = () => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const isDraggingFile = useIsDraggingFile();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    emitCustomEvent('resetSpans');
  }, [user]);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const currentUserId = sessionStorage.getItem(USER_ID)
      if(currentUserId) {
        const userResponse = await getUser(currentUserId);
        setUser(userResponse);
      }
    } catch {
      setUser(undefined);
      sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      sessionStorage.removeItem(USER_ID);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (userId: string): Promise<User> => {
    return fetch(`http://127.0.0.1:3000/users/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json())
  }

const login = async (email: string, password: string) => {
  await fetch('http://127.0.0.1:3000/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      sessionStorage.setItem(USER_ID, data.user.id);
      sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.token);
      setUser(data.user);
    })
    .catch((error) => {
      console.error('Error during login:', error);
    });
};

const updateUser = async (user: User) => {
  await fetch(`http://127.0.0.1:3000/users/${user?.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => response.json())
    .then((user) => {
      sessionStorage.setItem(USER_ID, user.id);
      setUser(user);
    })
    .catch((error) => {
      console.error('Error during login:', error);
    });
};

  const signOut = async () => {
    try {
      setUser(undefined);
      sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      sessionStorage.removeItem(USER_ID);
    } catch {}
  };

  const hasStoredAccessToken = (): boolean => {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    return !!accessToken;
  };

  const isExpectedLoggedIn = loading && hasStoredAccessToken();

  return (
    <AuthProvider
      value={{
        signOut,
        fetchUser,
        user,
        login,
        updateUser
      }}
    >
      <DragProvider value={isDraggingFile}>
        <BrowserRouter>
          {user || isExpectedLoggedIn ? (
            <>
              <Header />
              <Sidebar />
              <Pages />
            </>
          ) : (
            <Login />
          )}
        </BrowserRouter>
      </DragProvider>
    </AuthProvider>
  );
};

export default App;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import './SidebarButton.scss';
import { getRouteSteps } from '../../../utils/navigation';

const ROUTE_STEP_INDEX = 0;

interface Props {
  label: string;
  href: string;
  icon: string;
}

export const SidebarButton = ({ label, href, icon }: Props) => {
  const location = useLocation();
  const currentPage = getRouteSteps(location)[ROUTE_STEP_INDEX];
  const routeName = href.replace('/', '');

  return (
    <Link className={`sidebar-button sidebar-container${currentPage === routeName ? ' selected' : ''}`} to={href}>
      <ReactSVG
        src={icon}
        className={currentPage === routeName ? 'sidebar-selected-icon' : undefined}
        beforeInjection={(svg) => {
          svg.setAttribute('style', `width: 40px; height: auto; fill: ${currentPage === routeName ? '#0f0f0f' : '#ffffff'}`);
        }}
      />
        {label}
      </Link>
    
  );
};

import React, { ChangeEvent, ChangeEventHandler, InputHTMLAttributes, useEffect } from 'react';
import { FormElement } from '../FormElement/FormElement';

import './Input.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  small?: boolean;
}

export const Input = ({
  label,
  id,
  small = false,
  value,
  className = '',
  onChange,
  required = false,
  ...restProps
}: Props) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e);
  };

  return (
    <FormElement elementId={id} label={label} hasValue={!!value} required={required} small={small}>
      <input
        className={`input${className ? className : ''}${small ? ' small' : ''}`}
        onChange={handleChange}
        id={id}
        value={value}
        required={required}
        {...restProps}
      />
      <div className='border-animation' />
    </FormElement>
  );
};

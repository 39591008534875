import { useLocation } from 'react-router-dom';
import { getRouteSteps } from '../utils/navigation';

export const useRouteSteps = (hasTabs: boolean) => {
  const location = useLocation();
  const steps = getRouteSteps(location);

  const basePageRouteLength = hasTabs ? 2 : 1;
  const isBasePage = steps.length === basePageRouteLength;

  const getPage = (currentPageIndex: number) => {
    return steps[currentPageIndex];
  };

  return {
    isBasePage,
    steps,
    getPage,
  };
};

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pages } from '../../config/pages';

import { emitCustomEvent } from '../../utils/events';
import { RoutesGroup } from '../RoutesGroup/RoutesGroup';
import './Pages.scss';

export const Pages = () => {
  const location = useLocation();

  useEffect(() => {
    emitCustomEvent('resetSpans');
  }, [location]);

  return (
    <main className='page'>
      <RoutesGroup navElements={pages} />
    </main>
  );
};

import React, { ChangeEventHandler, InputHTMLAttributes, useEffect, useState } from 'react';
import './FileUpload.scss';
import { FormElement } from '../FormElement/FormElement';
import { ImagePreview } from './ImagePreview/ImagePreview';
import { useDragContext } from '../../context/DragContext';
import { EncodedImage, HostedImage, UploadedImage } from '../../types/filedata';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  value: string;
  small?: boolean;
  onImageUpload?: (image: EncodedImage, file: File) => void;
}

export const FileUpload = ({
  label,
  id,
  value,
  onImageUpload,
  small = false,
  placeholder,
}: Props) => {
  const [image, setImage] = useState<UploadedImage | HostedImage>({ preview: value });
  const dragging = useDragContext();
  const { preview } = image ?? {};

  const showPlaceholder = !preview || dragging;

  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImage = { ...file, preview: reader.result as EncodedImage };
        console.log({ file, newImage });
        setImage(newImage);
        onImageUpload?.(preview, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const renderPlaceholderText = () => {
    if (dragging) return 'drop file here';
    if (placeholder) return placeholder;
    return 'upload file';
  };

  return (
    <FormElement elementId={id} label={label} hasValue required small={small}>
      <div className={`image-upload${dragging ? ' dragging-file' : ''}`}>
        <input
          className='image-upload-input'
          type='file'
          id={id}
          accept='image/*'
          onChange={handleImageChange}
        />
        {showPlaceholder && <span>{renderPlaceholderText()}</span>}
        {!showPlaceholder && <ImagePreview previewUrl={preview} />}
      </div>
      {/* {image && <button onClick={handleUploadClick}>Upload Image</button>} */}
    </FormElement>
  );
};

import React, { useMemo, useRef, useState } from 'react';
import './RichTextEditor.scss';

import JoditEditor, { Jodit } from 'jodit-react';
import { FormElement } from '../FormElement/FormElement';
import { useLocaleContext } from '../../context/LocaleContext';

interface Props {
  id: string;
  label: string;
  value: string;
  small?: boolean;
  onBlur?: (value: string) => void;
}

export const RichTextEditor = ({ id, label, value, small = false, onBlur }: Props) => {
  const editor = useRef<Jodit>(null);
  const { locale } = useLocaleContext();

  const handleBlur = (newValue: string) => {
    addActiveClasses();
    onBlur?.(newValue);
  };

  const addActiveClasses = () => {
    const buttons = document.querySelectorAll(".jodit-toolbar-button__button[aria-pressed='true']");
    buttons.forEach((button) => {
      button.classList.add('active');
    });
  };

  const removeActiveClasses = () => {
    const buttons = document.querySelectorAll(
      ".jodit-toolbar-button__button[aria-pressed='false']"
    );
    buttons.forEach((button) => {
      button.classList.remove('active');
    });
  };

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: 'Post content',
      theme: 'dark',
      language: locale,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      size: 300,
    }),
    []
  );

  return (
    <FormElement
      onFocus={removeActiveClasses}
      elementId={id}
      label={label}
      hasValue
      required
      small={small}
    >
      <JoditEditor
        value={value}
        className='rich-text-editor'
        ref={editor}
        config={{
          ...config,
          defaultActionOnPaste: 'insert_only_text',
          statusbar: false,
          toolbarButtonSize: 'large',
          removeButtons: [
            'font',
            'file',
            'speechRecognize',
            'spellcheck',
            'source',
            'table',
            'symbols',
            'ai-commands',
            'ai-assistant',
            'print',
            'classSpan',
            'find',
            'about',
            'selectall',
            'superscript',
            'subscript',
            'copyformat',
            'copy',
            'cut',
            'paste',
          ],
        }}
        onBlur={handleBlur} // preferred to use only this option to update the content for performance reasons
        // onChange={}
      />
    </FormElement>
  );
};

import React, { PropsWithChildren, createContext, useContext } from 'react';
import { LOCALES } from '../config/locales';

interface LocaleContextValue {
  locale: LOCALES;
}
type LocaleProviderProps = PropsWithChildren<{ value: LocaleContextValue }>;

const initialValue: LocaleContextValue = {
  locale: LOCALES.EN,
};

const LocaleContext = createContext<LocaleContextValue>(initialValue);

export const LocaleProvider = ({ value, children }: LocaleProviderProps) => {
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};

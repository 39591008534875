import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Tab.scss';
import { getRouteSteps } from '../../../utils/navigation';

const ROUTE_STEP_INDEX = 1;

interface Props {
  label: string;
  href: string;
}

export const Tab = ({ label, href }: Props) => {
  const location = useLocation();
  const currentPage = getRouteSteps(location)[ROUTE_STEP_INDEX];
  const routeName = href.replace('/', '');

  return (
    <Link className={`tab${currentPage === routeName ? ' selected' : ''}`} to={href}>
      {label}
    </Link>
  );
};
